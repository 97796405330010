<template>
  <div class="area_view">
    <TableHead :title="title" :isEssential="isEssential">
      <template v-slot:headRight>
        <button
          type="button"
          class="btn_secondary btn_medium"
          :disabled="disabled"
          @click="onClickPopPartner"
        >
          업체선택
        </button>
      </template>
    </TableHead>
    <Board :disableHover="true" :dataList="postPersonList" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 254px" />
        <col style="width: 212px" />
        <col style="width: 212px" />
        <col style="width: 224px" />
        <col style="width: 200px" />
        <col style="width: 138px" />
      </template>
      <template v-slot:tr>
        <th>대상 업체</th>
        <th>파트너사 담당자</th>
        <th>담당 업무</th>
        <th>이메일</th>
        <th>휴대전화</th>
        <th />
      </template>
      <template v-slot:rows>
        <template v-for="item in postPersonList">
          <tr :key="item.personNum">
            <td class="td_ellip">
              <button
                :class="['link_subject', 'align_center']"
                :data-ellip="item.companyName"
                @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${item.personNum}`)"
              >
                {{ item.companyName || "" }}
              </button>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.job }}</td>
            <td class="td_ellip align_left" :data-ellip="item.email">
              {{ item.email }}
            </td>
            <td>{{ item.mobile }}</td>
            <td class="align_right">
              <button
                type="button"
                class="btn_fourthly btn_small"
                :disabled="disabled"
                @click="onClickRemove(item)"
              >
                삭제
              </button>
            </td>
          </tr>
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="6" class="td_empty">
            선택한 업체가 없습니다.
            <!-- <div class="area_right">
                <button type="button" disabled="disabled" class="btn_fourthly btn_small">삭제</button>
            </div> -->
          </td>
        </tr>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "@/components/common/board/Board";
import TableHead from "@/components/shared/TableHead";

export default {
  name: "RequestPartnersInfo",
  components: {
    Board,
    TableHead,
  },
  props: {
    title: String,
    postPersonList: Array,
    isEssential: Boolean,
    disabled: Boolean,
  },
  methods: {
    onClickPopPartner() {
      this.$emit("onClickPopPartner");
    },
    onClickRemove(item) {
      this.$emit("onClickRemoveItem", item);
    },
  },
};
</script>
