<template>
  <tr :class="{ tr_select: isSelected }">
    <td>
      <CheckboxItem :id="rowData" :checkedNames.sync="checkedNamesSync" />
    </td>
    <td class="td_ellip" :data-ellip="rowData.companyName">
      {{ rowData.companyName }}
    </td>
    <td>{{ rowData.name }}</td>
    <td class="td_ellip" :data-ellip="rowData.job">
      {{ rowData.job }}
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.categoryItem">
      {{ rowData.categoryItem }}
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.email">
      {{ rowData.email }}
    </td>
    <td>{{ rowData.mobile }}</td>
    <td>{{ rowData.corporateNum }}</td>
  </tr>
</template>

<script>
//  "userNum": 32,
//  "name": "8",
//  "status": "C",
//  "companyName": "888",
//  "email": "8@8",
//  "tel": "02-888-8888",
//  "job": "8"
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";

export default {
  name: "AnnouncePartnerPopupLine",
  components: {
    CheckboxItem,
  },
  props: {
    checkedNames: Array,
    rowData: {
      id: String,
      title: Number,
    },
  },
  computed: {
    isSelected() {
      const ret = this.checkedNames.find((item) => String(item) === String(this.rowData.id));

      return !!ret;
    },
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
  },
};
</script>
