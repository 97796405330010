<template>
  <Popup popupType="tableType" width="588px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">조직도 검색</h3>
      </div>
      <div class="body_section">
        <LdapPopupFilterBar @onClickSearch="onClickSearch" @alert="alert" />
        <Board
          :dataList="dataList"
          :isScroll="true"
          scrollHeight="calc(100vh - 453px)"
          scrollMaxHeight="294px"
          scrollMinHeight="49px"
          :showPagination="false"
        >
          <template v-slot:colgroup>
            <col style="width: 156px" />
            <col style="width: 144px" />
            <col />
          </template>
          <template v-slot:tr>
            <th class="align_left" style="width: 144px">이름</th>
            <th class="align_left" style="width: 156px">사번</th>
            <th class="align_left" style="width: 264px">소속&#47;파트</th>
          </template>
          <template v-slot:rows>
            <template v-for="(item, index) in dataList">
              <LdapPopupLine
                :key="index"
                :isSelected="getIsSelected(item)"
                :isDisabled="!getIsSelected(item) && getIsDisabled(item)"
                :rowData="item"
                @onClickSelectItem="onClickSelectItem"
              />
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="3">검색 결과가 없습니다</td>
            </tr>
          </template>
        </Board>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">취소</button>
      <button
        :disabled="!selectedItem"
        type="submit"
        class="btn_secondary btn_medium"
        @click="onClickSelect"
      >
        선택
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import LdapPopupFilterBar from "@/components/layout/popup/ldapPopup/LdapPopupFilterBar";
import LdapPopupLine from "./LdapPopupLine";
import Board from "@/components/common/board/Board";
import ApiService from "@/services/ApiService";

export default {
  name: "LdapPopup",
  components: {
    Popup,
    LdapPopupFilterBar,
    LdapPopupLine,
    Board,
  },
  props: {
    isManager: {
      type: String,
      default: "N",
    },
    disabledDataList: {
      type: Array,
      default: () => [],
    },
    defaultSelectedItem: Object,
  },
  data() {
    return {
      // dataList: [{},{},{},{},{},{},{}]
      dataList: [],
      selectedItem: this.defaultSelectedItem || null,
    };
  },
  created() {
    const path = `${this.$apiPath.MANAGER_LDAP}?name=&isManager=${this.isManager}`;
    this.getData(path);
  },
  methods: {
    getIsSelected(item) {
      if (!this.selectedItem) return false;

      return (
        this.selectedItem.accountId === item.accountId ||
        this.selectedItem.loginId === item.accountId
      );
    },
    getIsDisabled(item) {
      if (!this.disabledDataList) return false;
      return (
        Boolean(
          this.disabledDataList.find((disabledItem) => disabledItem.loginId == item.accountId),
        ) && this.defaultSelectedItem.loginId != item.accountId
      );
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickSearch(obj) {
      const { text } = obj;
      const path = `${this.$apiPath.MANAGER_LDAP}?name=${text}&isManager=${this.isManager}`;
      this.getData(path);
    },
    async getData(path) {
      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      this.dataList = result.data;
    },
    onClickSelectItem(rowData) {
      this.selectedItem = rowData;
    },
    onClickSelect() {
      if (!this.selectedItem) return;

      this.$emit("onClickSelect", this.selectedItem);
    },
    alert(text) {
      this.$emit("alert", text);
    },
  },
};
</script>
