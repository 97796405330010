<template>
  <Popup popupType="tableType" width="841px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">담당자 추가/삭제</h3>
      </div>
      <div class="body_section">
        <div class="area_left" style="width: 564px">
          <LdapPopupFilterBar @onClickSearch="onClickSearch" @alert="alert" />
          <Board
            :dataList="dataList"
            :isScroll="true"
            scrollHeight="calc(100vh - 453px)"
            scrollMaxHeight="343px"
            scrollMinHeight="49px"
            :showPagination="false"
          >
            <template v-slot:colgroup>
              <col style="width: 156px" />
              <col style="width: 144px" />
              <col />
            </template>
            <template v-slot:tr>
              <th class="align_left" style="width: 156px">ID</th>
              <th class="align_left" style="width: 144px">이름</th>
              <th class="align_left" style="width: 264px">소속&#47;파트</th>
            </template>
            <template v-slot:rows>
              <template v-for="(item, index) in dataList">
                <LdapPopupLine
                  :key="index"
                  :isSelected="getIsSelected(item)"
                  :isDisabled="!getIsSelected(item) && getIsDisabled(item)"
                  :rowData="item"
                  @onClickSelectItem="onClickSelectItem"
                />
              </template>
            </template>
            <template v-slot:emptyList>
              <tr>
                <td colspan="3">검색 결과가 없습니다</td>
              </tr>
            </template>
          </Board>
        </div>
        <div class="area_right" style="width: 229px">
          <div class="group_form">
            선택<span class="txt_view_r"
              >{{ selectedDataListSync.length }}{{ max ? " / " + max : "" }}</span
            >
          </div>
          <LdapTagList :dataList="selectedDataListSync" @onClickRemoveTag="onClickRemoveTag" />
        </div>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">취소</button>
      <button type="submit" class="btn_secondary btn_medium" @click="onClickSelect">선택</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import LdapPopupFilterBar from "@/components/layout/popup/ldapPopup/LdapPopupFilterBar";
import LdapPopupLine from "./LdapPopupLine";
import Board from "@/components/common/board/Board";
import LdapTagList from "@/components/common/ldapTagList/LdapTagList";
import ApiService from "@/services/ApiService";

export default {
  name: "LdapMultiplePopup",
  components: {
    Popup,
    LdapPopupFilterBar,
    LdapPopupLine,
    Board,
    LdapTagList,
  },
  props: {
    isManager: {
      type: String,
      default: "N",
    },
    max: {
      type: Number,
      default: null,
    },
    disabledDataList: {
      type: Array,
      default: () => [],
    },
    // skillDisabledDataList:{
    //   type: Array,
    //   default: []
    // },
    // priceDisabledDataList:{
    //   type: Array,
    //   default: []
    // },
    selectedDataList: Array,
    isBidView: Boolean,
  },
  data() {
    return {
      dataList: [],
      selectedDataListSync: this.selectedDataList.concat(),
      disabledDataListSync: this.disabledDataList,
      // skillDisabledDataListSync: this.skillDisabledDataList,
      // priceDisabledDataListSync: this.priceDisabledDataList,
    };
  },
  mounted() {
    const path = `${this.$apiPath.MANAGER_LDAP}?name=&isManager=${this.isManager}`;
    this.getData(path);
  },
  methods: {
    getIsSelected(checkItem) {
      if (!this.selectedDataListSync) return false;
      return Boolean(
        this.selectedDataListSync.find(
          (item) => item.accountId == checkItem.accountId || item.loginId == checkItem.accountId,
        ),
      );
    },
    getIsDisabled(item) {
      if (!this.disabledDataListSync) return false;
      return (
        Boolean(
          this.disabledDataListSync.find((disabledItem) => disabledItem.loginId == item.accountId),
        ) && this.selectedDataList.accountId != item.accountId
      );
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickSearch(obj) {
      const { text } = obj;
      const path = `${this.$apiPath.MANAGER_LDAP}?name=${text}&isManager=${this.isManager}`;
      this.getData(path);
    },
    async getData(path) {
      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      this.dataList = result.data;
    },
    onClickSelectItem(rowData) {
      if (
        this.selectedDataListSync.find(
          (item) => item.accountId == rowData.accountId || item.loginId == rowData.accountId,
        )
      ) {
        const isSelected = this.getIsSelected(rowData);
        if (!isSelected) {
          this.onClickRemoveTag(rowData);
        }
        return;
      } else {
        if (this.max && this.selectedDataListSync.length == this.max) {
          this.alert(this.max + "개까지 선택가능합니다.");
          return;
        }
        this.selectedDataListSync.push(rowData);
      }
    },
    onClickSelect() {
      let bidView = false;
      if (this.isBidView) {
        bidView = true;
      }
      this.$emit("onClickSelect", this.selectedDataListSync, bidView);
      // this.$emit( 'onClickClose' );
    },
    onClickRemoveTag(removeItem) {
      // 태그 삭제
      const removeId = removeItem.accountId || removeItem.loginId;
      this.selectedDataListSync = this.selectedDataListSync.filter((item) =>
        item.accountId ? item.accountId != removeId : item.loginId != removeId,
      );

      if (this.disabledDataList && this.disabledDataList.length > 0) {
        this.disabledDataListSync = this.disabledDataListSync.filter((item) =>
          item.accountId ? item.accountId != removeId : item.loginId != removeId,
        );
      }

      //this.onClickSelectItem( removeItem);
    },
    alert(text) {
      this.$emit("alert", text);
    },
  },
};
</script>
<style scoped>
.body_section {
  overflow: hidden;
}
.area_right {
  margin-left: 24px;
  padding: 23px 24px;
  border-top: 1px solid #222;
  border-bottom: 1px solid #e1e1e1;
  box-sizing: border-box;
}
.area_right .group_form {
  font-size: 12px;
  line-height: 18px;
  color: #222;
}
.area_right .txt_view_r {
  line-height: 18px;
}
.area_right /deep/ .list_tag_ldap {
  height: 412px;
  max-height: inherit;
}
.area_right /deep/ .list_tag_ldap li {
  width: 100%;
}
.area_right /deep/ .btn_tag_ldap {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-right: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.area_right /deep/ .btn_tag_ldap .ico_cross3 {
  position: absolute;
  top: 10px;
  right: 12px;
  margin: 0;
}
</style>
