const ManagerLdapMixin = {
  data() {
    return {
      ldapManagerType: "",

      evaluatePersonList: [],
      skillEvaluatePersonList: [],
      priceEvaluatePersonList: [],

      disabledDataList: [],

      isEditMode: false,
      editTargetItem: {},

      isPopLdapMultiple: false,
      isPopLdapChange: false,

      skillDisabledDataList: [],
      priceDisabledDataList: [],
    };
  },
  methods: {
    addManager(obj, code) {
      if (obj.loginId) {
        // this.managerList = this.managerList.concat(obj);
        return obj;
      }
      const { accountId, emailId, personName, displayName, employeeNo } = obj;

      const manager = {
        email: emailId,
        loginId: accountId,
        employeeNo: employeeNo,
        loginEmpNo: employeeNo,
        name: personName || displayName,
      };

      if (code) {
        manager.code = code;
      }
      // this.managerList = this.managerList.concat(manager);
      return manager;
    },
    onClickClosePopLdapManager() {
      // 팝업 닫기
      this.isPopLdapMultiple = false;
      this.isPopLdapChange = false;
    },
    onClickPopLdapMultiple(type) {
      // 멀티Ldap검색팝업(추가/삭제) 열기
      if (type) {
        this.ldapManagerType = type;
      }
      this.setDisabledDataList();
      this.isPopLdapMultiple = true;
    },
    onClickPopLdapChange(item, type) {
      // Ldap검색팝업(변경) 열기
      if (type) {
        this.ldapManagerType = type;
      }
      this.setDisabledDataList();
      this.isPopLdapChange = true;
      this.editTargetItem = item;
    },
    onClickSelectLdapMultiple(rowData, isBidView = false) {
      // 멀티Ldap검색팝업(추가/삭제) 선택완료
      this.isPopLdapMultiple = false;

      if (this.ldapManagerType) {
        const code = this.ldapManagerType == "skill" ? "S" : "C";
        rowData = rowData.map((item) => {
          return this.addManager(item, code);
        });
        if (this.ldapManagerType == "skill") {
          this.skillEvaluatePersonList = rowData;
        } else {
          this.priceEvaluatePersonList = rowData;
        }
        this.evaluatePersonList = this.skillEvaluatePersonList.concat(this.priceEvaluatePersonList);
        if (isBidView) {
          this.saveSelectedManager(rowData, code);
        }
      } else {
        rowData = rowData.map((item) => {
          return this.addManager(item);
        });
        this.managerLdapDataList = rowData;
      }
      this.setDisabledDataList();
      this.ldapManagerType = "";
    },
    onClickSelectLdapChange(changeItem) {
      // Ldap검색팝업(변경) 선택완료
      this.isPopLdapChange = false;
      if (changeItem) {
        if (this.ldapManagerType == "skill") {
          this.skillEvaluatePersonList = this.skillEvaluatePersonList.map((item) => {
            const dataListItemId = item.loginId;
            const targetId = this.editTargetItem.loginId;
            if (dataListItemId == targetId) {
              changeItem = this.addManager(changeItem, this.editTargetItem.code);
              changeItem.personNum = this.editTargetItem.personNum;
              return changeItem;
            } else {
              return item;
            }
          });
        } else {
          this.priceEvaluatePersonList = this.priceEvaluatePersonList.map((item) => {
            const dataListItemId = item.loginId;
            const targetId = this.editTargetItem.loginId;
            if (dataListItemId == targetId) {
              changeItem = this.addManager(changeItem, this.editTargetItem.code);
              changeItem.personNum = this.editTargetItem.personNum;
              return changeItem;
            } else {
              return item;
            }
          });
        }
      }
      this.editTargetItem = {};
      this.ldapManagerType = "";
    },
    onClickRemoveTag(removeItem, managerType = "") {
      // 태그 삭제

      const evaluationMode =
        (this.skillEvaluatePersonList && this.skillEvaluatePersonList.length != 0) ||
        (this.priceEvaluatePersonList && this.priceEvaluatePersonList.length != 0);
      if (evaluationMode) {
        if (managerType != "" && this.ldapManagerType == "") {
          this.ldapManagerType = managerType;
        }

        if (this.ldapManagerType == "skill") {
          this.skillEvaluatePersonList = this.skillEvaluatePersonList.filter(
            (item) => item.loginId != removeItem.loginId,
          );
        } else {
          this.priceEvaluatePersonList = this.priceEvaluatePersonList.filter(
            (item) => item.loginId != removeItem.loginId,
          );
        }
        this.ldapManagerType = "";
      }
      if (this.managerLdapDataList && this.managerLdapDataList.length != 0) {
        this.managerLdapDataList = this.managerLdapDataList.filter(
          (item) => item.loginId != removeItem.loginId,
        );
      }
    },
    setDisabledDataList() {
      // if(this.evaluatePersonList){
      //   this.disabledDataList = this.skillEvaluatePersonList.concat(this.priceEvaluatePersonList);
      // }
      // if(this.managerLdapDataList){
      //   this.disabledDataList = this.managerLdapDataList;
      // }

      // 기술 - 가격평가자 동시에 설정할수있도록 수정
      if (this.ldapManagerType == "skill") {
        this.disabledDataList = this.skillEvaluatePersonList;
      } else {
        this.disabledDataList = this.managerLdapDataList;
      }
    },
    setEvaluatePersonList() {
      this.skillEvaluatePersonList = this.evaluatePersonList.filter((item) => item.code == "S");
      this.priceEvaluatePersonList = this.evaluatePersonList.filter((item) => item.code == "C");
    },
  },
};
export default ManagerLdapMixin;
