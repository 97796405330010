import ApiService from "@/services/ApiService";

const FileMixin = {
  methods: {
    async getFileUrl(attachFileSeq) {
      const path = `${this.$apiPath.ATTACH_FILE}/${attachFileSeq}`;
      const result = await ApiService.shared.getFile(path);

      if (!result) {
        alert("파일 다운로드에 실패했습니다.");
        return;
      }
      var newBlob = new Blob([result]);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   window.navigator.msSaveOrOpenBlob(newBlob);
      //   return;
      // }

      return window.URL.createObjectURL(newBlob);
    },
  },
};

export default FileMixin;
