import ApiService from "@/services/ApiService";
import { makeQueryStringByObject } from "@/utils/urlUtils";

export default {
  methods: {
    // 레퍼런스 정보 가져오기
    async getReferenceInfo(type, requestCid) {
      const path = `${this.$apiPath.ST_COMMON}/${type}/${requestCid}`;
      const result = await ApiService.shared.getData(path);
      return result.data;
    },
    //구매요청서 가져오기
    async getPurchaseContract(requestCid) {
      const obj = {
        requestCid: requestCid,
        size: 1,
        page: 1,
      };
      const path = `${this.$apiPath.ST_PURCHASE_REQUEST}${makeQueryStringByObject(obj)}`;
      const result = await ApiService.shared.getData(path);
      if (result.data && result.data[0]) {
        return result.data[0];
      }
      return {};
    },
  },
};
