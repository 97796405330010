<template>
  <Popup popupType="tableType" width="980px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">카탈로그 목록</h3>
      </div>
      <div class="body_section">
        <Board
          :isScroll="true"
          scrollHeight="calc(100vh - 315px)"
          scrollMaxHeight="490px"
          scrollMinHeight="131px"
          :showPagination="false"
          :dataList="dataList"
          :maxRowCount="size"
          :totalPageCount="totalPageCount"
        >
          <template v-slot:colgroup>
            <col style="width: 216px" />
            <!-- 등록일자 -->
            <col style="width: 392px" />
            <!-- 카탈로그명 -->
            <col style="width: 308px" />
            <!-- 첨부파일 -->
          </template>
          <template v-slot:tr>
            <th style="width: 216px">등록일자</th>
            <th style="width: 392px">카탈로그명</th>
            <th style="width: 308px">첨부파일</th>
          </template>
          <template v-slot:rows>
            <template v-for="item in dataList">
              <CatalogListLine
                :key="item.brochureSeq"
                :rowData="item"
                @onClickLine="$emit('onClickCatalogLine', item)"
              />
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="3" class="td_empty">
                <div
                  class="inner_empty"
                  style="height: calc(100vh - 343px); max-height: 460px; min-height: 103px"
                >
                  <div class="txt_empty">
                    <span class="ico_koop ico_exclam_error_t" />카탈로그가 없습니다.
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </Board>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_tertiary btn_medium" @click="onClickClose">닫기</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import Board from "@/components/common/board/Board";
import CatalogListLine from "./CatalogListLine";

import SimpleListMixin from "@/mixins/SimpleListMixin";

export default {
  components: {
    Popup,
    TableView,
    Board,
    CatalogListLine,
  },
  mixins: [SimpleListMixin],
  props: {
    companyNo: String,
  },
  data() {
    return {
      API_PATH: this.$apiPath.BROCHURE_SHOW,
      size: 900, // 임시 테스트.
    };
  },
  beforeMount() {
    this.getDataInit();
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    getCurrentParams() {
      const params = {};

      if (this.companyNo) {
        params.companyRgstNo = this.companyNo;
      }

      return params;
    },
  },
};
</script>
