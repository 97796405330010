import ApiService from "@/services/ApiService";

import { board } from "@/constants/options";
import { makeQueryStringByObject } from "@/utils/urlUtils";

const SimpleListMixin = {
  data() {
    return {
      dataList: [],
      size: board.DEFAULT_MAX_ROW_COUNT, // 한번에 불러오는 아이템 개수.

      totalPageCount: 0,
      currentPageIndex: 0,

      defaultKeywordSelectedId: "title",
      defaultKeywordInputText: "",
    };
  },
  computed: {
    maxPaginationCount() {
      return board.MAX_PAGINATION_COUNT;
    },
    defaultRowCount() {
      return board.DEFAULT_MAX_ROW_COUNT;
    },
  },
  methods: {
    getDataInit() {
      // 현재 검색 기준 params
      const currentParams = this.getCurrentParams();

      const params = Object.assign({}, currentParams, {
        page: "1",
        size: this.size,
      });

      this.getData(params);
    },
    async getData(params) {
      // 특정 페이지에만 들어가는 값이 있다.
      // ex) 공지사항 페이지 - params.searchType = "M";
      if (this.ADD_PARAMS) {
        params = Object.assign(params, this.ADD_PARAMS);
      }

      const urlRest = makeQueryStringByObject(params);
      const url = this.API_PATH + urlRest;

      const result = await ApiService.shared.getData(path);
      const { data, total } = result;
      if (data && total) {
        this.dataList = data;
        this.totalPageCount = parseInt(total);
      } else {
        this.dataList = [];
        this.totalPageCount = 0;
      }
    },
    onChangePage(nPageIndex) {
      this.currentPageIndex = nPageIndex;

      // 현재 검색 기준 params
      const currentParams = this.getCurrentParams();

      const newParams = Object.assign({}, currentParams, {
        page: nPageIndex + 1,
        size: this.size,
      });

      this.getData(newParams);
    },
    onChangeRowCount(value) {
      this.currentPageIndex = 0;
      this.size = value;

      // 현재 검색 기준 params
      const currentParams = this.getCurrentParams();

      let newParams = Object.assign({}, currentParams, {
        page: this.currentPageIndex + 1,
        size: this.size,
      });

      this.getData(newParams);
    },
    onClickSearch(objData) {
      this.defaultKeywordSelectedId = objData.keywordSelectedId;
      this.defaultKeywordInputText = objData.keywordInputText;

      let params = this.getCurrentParams();

      params.page = "1";
      params.size = this.size;

      this.currentPageIndex = Number.parseInt(params.page) - 1;
      this.size = Number.parseInt(params.size);

      this.getData(params);
    },
    getCurrentParams() {
      // override this.
      return {};
    },
  },
};
export default SimpleListMixin;
