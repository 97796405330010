<template>
  <div class="dialogue_comm" @mousemove="checkHoverTip">
    <div class="dialogue_head">
      <slot name="dialogueHead" />
    </div>
    <div class="box_dialogue" :style="styleCss">
      <strong class="tit_dialogue">{{ itemData.itemName }}</strong>
      <dl class="info_dialogue">
        <dt>분류</dt>
        <dd>{{ itemData.category | categoryText }}</dd>
        <dt>품목코드</dt>
        <dd>{{ itemData.displayCode }}</dd>
        <dt>단위</dt>
        <dd>{{ itemData.unitValue | nullToDash }}</dd>
        <dt>단가</dt>
        <dd>{{ Number(itemData.price) | nullToDash | currency }}</dd>
        <dt>제조사</dt>
        <dd>{{ itemData.manufacturer | nullToDash }}</dd>
        <dt>보증기간</dt>
        <dd>{{ Number(itemData.warranty) ? `${itemData.warranty} 개월` : "-" }}</dd>
        <dt>규격</dt>
        <dd>{{ itemData.standard }}</dd>
        <dt>품목상세</dt>
        <dd v-html="noteDecoded"></dd>
      </dl>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import { escapeRegExp } from "@/utils/spUtils";
export default {
  name: "DialougeItem",
  props: {
    isToggle: Boolean,
    itemCode: String,
  },
  data() {
    return {
      tooltipSet: {},
      itemData: {},
    };
  },
  computed: {
    styleCss() {
      let style = "";
      if (this.isToggle) {
        style = "display:block;";
      } else {
        if (!this.tooltipSet.mouseX) return;
        style = "left:" + this.tooltipSet.mouseX + "px;top:" + this.tooltipSet.mouseY + "px";
      }
      return style;
    },
    noteDecoded() {
      if (!this.itemData.note) {
        return "-";
      }
      const note = this.itemData.note;

      return escapeRegExp(note);
    },
  },
  created() {
    if (!this.itemCode) return;
    const path = `${this.$apiPath.ITEM}/${this.itemCode}`;
    this.getData(path);
  },
  methods: {
    async getData(path) {
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.itemData = data;
    },
    checkHoverTip(e) {
      if (this.isToggle) {
        return;
      }
      const result = { mouseX: e.clientX, mouseY: e.clientY };
      this.tooltipSet = result;
    },
  },
};
</script>
<style lang="scss" scpoed>
/* dialogue */
.dialogue_comm {
  display: inline-block;
  vertical-align: top;
}
.dialogue_head .txt_dialogue {
  display: inline-block;
  text-align: center;
  vertical-align: top;
}

.box_dialogue {
  display: none;
  position: fixed;
  z-index: 100;
  overflow-y: auto;
  width: 206px;
  max-height: 245px;
  margin: 0 0 0 20px;
  padding: 12px 16px;
  border: 1px solid #222;
  font-size: 12px;
  background-color: #fff;
  color: #555;
  text-align: left;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    border: {
      top: 4px solid #000000;
      left: 4px solid #000000;
      right: 4px solid transparent;
      bottom: 4px solid transparent;
    }
    pointer-events: none;
  }
}

.box_dialogue .tit_dialogue {
  display: block;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #222;
}
.box_dialogue .info_dialogue {
  margin: 8px 0;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.box_dialogue .info_dialogue dt {
  float: left;
  clear: left;
  display: block;
  width: 60px;
}
.box_dialogue .info_dialogue dd {
  float: left;
  display: block;
  width: calc(100% - 60px);
  box-sizing: border-box;
  padding-left: 2px;
}
.box_dialogue .desc_dialogue {
  padding-top: 8px;
  border-top: 1px solid #e6e6e6;
}

.dialogue_head:hover + .box_dialogue {
  display: inline-block;
}
</style>
