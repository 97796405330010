<template>
  <Popup popupType="tableType">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">내거래처등록</h3>
        <p v-if="clientData.clientYn" class="desc_tip tc_blue">
          <span class="ico_koop ico_exclam_b" />내거래처로 등록되어 있습니다.
        </p>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 428px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>거래처명</th>
              <td>{{ clientData.companyNm }}</td>
            </tr>
            <tr>
              <th>대표자명</th>
              <td>{{ clientData.representativeNm }}</td>
            </tr>
            <tr>
              <th>사업자등록번호</th>
              <td>{{ clientData.companyRgstNo }}</td>
            </tr>
            <tr>
              <th>회사전화번호</th>
              <td>{{ clientData.telNo }}</td>
            </tr>
          </template>
        </TableView>
        <!-- <div class="tbl_foot">
          <p class="desc_tip"><span class="ico_koop ico_exclam"></span>동일한 직원에게 초대 발송은 1일 1회만 가능합니다.</p>
          <p class="desc_tip"><span class="ico_koop ico_exclam"></span>휴대전화번호는 초대 발송 용도로만 사용되며 개인정보로 저장하지 않습니다.</p>
        </div> -->
      </div>
    </div>
    <div class="group_btn">
      <button
        v-if="!clientData.clientYn"
        type="button"
        class="btn_tertiary btn_medium"
        @click="onClickClose"
      >
        닫기
      </button>
      <button
        v-if="!clientData.clientYn"
        type="submit"
        class="btn_primary btn_medium"
        @click="onClickSubmit"
      >
        내거래처등록
      </button>
      <button v-else type="button" class="btn_primary btn_medium" @click="onClickClose">
        확인
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import ApiService from "@/services/ApiService";

// import { ALERT_ACTION, START_TOAST_ACTION } from '@/store/modules/alert/action';

export default {
  name: "ClientStraightPopup",
  components: {
    Popup,
    TableView,
  },
  props: {
    clientData: Object,
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    async onClickSubmit() {
      const obj = {
        companyName: this.clientData.companyNm,
        clientCompanyRgstNo: this.clientData.companyRgstNo,
        ownerName: this.clientData.representativeNm,
        telNo: this.clientData.telNo,
        remark: "",
      };

      let toastMsg = "";
      const result = await ApiService.shared.postData(`${this.$apiPath.CLIENT}`, obj);

      if (result.code !== "200") {
        // this.alert( result.response.data.text );
        this.alert(result.text);
        return;
      }
      toastMsg = "거래처가 등록되었습니다";
      // this.$store.dispatch(START_TOAST_ACTION, toastMsg);
      this.$emit("setData");
      //this.goToView(this.itemId);
      // this.goToList();
      this.$emit("onClickClose");
    },
  },
};
</script>
