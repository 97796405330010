import { getMoneyToNumber } from "@/utils/stringUtils";

export default class EstimateGoodsListModel {
  constructor(obj) {
    this.objRaw = {};

    this.amount = "";
    this.itemCode = ""; // 품목코드
    this.itemName = "";
    this.displayCode = "";
    this.categoryName = ""; // 품명
    this.assetInfoNum = "";
    this.unitPrice = "";
    this.price = "";
    this.partnerName = "";
    this.warranty = "";

    if (obj) {
      this.setData(obj);
    }
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);

    const {
      displayCode,
      itemCode,
      itemName,
      categoryName,
      quantity,
      price,
      unitPrice,
      partnerName,
      warranty,
    } = obj;

    this.itemCode = itemCode || "";
    this.itemName = itemName || "";
    this.displayCode = displayCode || "";
    this.categoryName = categoryName || itemName || "";
    this.quantity = String(quantity) || "";
    this.price = price || "";
    this.unitPrice = unitPrice || "";
    this.partnerName = partnerName || "";
    this.warranty = warranty || "";
  }
  setItemData(obj) {
    this.objRaw = _.cloneDeep(obj);

    const {
      displayCode,
      itemCode,
      itemName,
      categoryName,
      quantity,
      price,
      partnerName,
      warranty,
    } = obj;

    this.itemName = itemName || "";
    this.categoryName = itemName || "";
    this.displayCode = displayCode || "";
    this.itemCode = itemCode || "";
    this.quantity = String(quantity) || "";
    this.price = price || "-";
    this.partnerName = partnerName || "";
    this.warranty = warranty || "";
  }

  getData() {
    let obj = {
      // categoryId: this.categoryId,
      itemCode: this.itemCode,
      // assetInfoNum: this.assetInfoNum,
      // accountName: this.accountName,
      // accountCode: this.accountCode,
      // cmdbAssetInfo: { assetInfoNum: this.assetInfoNum },
    };

    return Object.assign({}, this.objItemData, this.objRaw, obj);
  }

  // getAlertMessage() {
  //   if (this.amount === 0 || this.amount === '0' || !this.amount)
  //     return '품목의 수량을 확인해주세요.';
  //   if (this.unitPrice !== 0 && !this.unitPrice) return '품목의 단가를 확인해주세요.';

  //   return null;
  // }
}
