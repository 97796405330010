<template>
  <ListRow>
    <td>{{ rowData.createDt | dateStringFormat }}</td>
    <td class="td_ellip" :data-ellip="rowData.brochureTitle">
      <button class="link_subject" @click="$emit('onClickLine')">
        {{ rowData.brochureTitle }}
      </button>
      <!-- <router-link
        :to="toPath" 
        class="link_subject"
        :data-ellip="rowData.brochureTitle"
        v-html="rowData.brochureTitle"
      ></router-link>-->
    </td>
    <td class="align_left">
      {{ rowData.brochureFileNm1 || "-" }}
    </td>
  </ListRow>
</template>
<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";

export default {
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
};
</script>
