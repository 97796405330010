<template>
  <div>
    <!--v-if="isCanAddManager" -->
    <div class="group_form">
      <button
        v-if="!isEditModeSync"
        type="button"
        class="btn_fourthly btn_small"
        @click="$emit('onClickPopLdapMultiple')"
      >
        담당자 추가/삭제
      </button>
      <button
        v-if="isCanEdit && !isEditModeSync"
        type="button"
        class="btn_fourthly btn_small"
        :disabled="dataListSync.length == 0"
        @click="onClickEditTagModeOn"
      >
        담당자 변경
      </button>
      <button
        v-if="isEditModeSync"
        type="button"
        class="btn_fourthly btn_small"
        @click="onClickEditTagModeOff"
      >
        취소
      </button>
      <button
        v-if="isEditModeSync"
        type="button"
        class="btn_tertiary btn_small"
        @click="onClickEditTagCompleted"
      >
        완료
      </button>
    </div>
    <LdapTagList
      v-if="dataListSync.length > 0"
      :isViewMode="isViewMode"
      :isEditMode="isEditModeSync"
      :dataList="dataListSync"
      @onClickEditTag="(item) => $emit('onClickPopLdapChange', item)"
      @onClickRemoveTag="(item) => $emit('onClickRemoveTag', item)"
    />
  </div>
</template>

<script>
import LdapTagList from "@/components/common/ldapTagList/LdapTagList";
import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "ManagerLdap",
  components: {
    LdapTagList,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    // orginEvaluatePersonList: {
    //   type: Array,
    //   default: []
    // },
    isCanEdit: {
      type: Boolean,
      default: true,
    },
    isViewMode: Boolean,
    isEditMode: Boolean,
    isSkill: Boolean,
    isPrice: Boolean,
    isWriting: Boolean,
    isBidView: Boolean,
    isAnnounceView: Boolean,
    canAddOrChange: Boolean,
    isFromPurchase: Boolean,
  },
  data() {
    return {
      beforeDataList: [], // 변경모드 전 데이터
    };
  },
  computed: {
    dataListSync: {
      get() {
        return this.dataList;
      },
      set(value) {
        this.$emit("update:dataList", value);
      },
    },
    isEditModeSync: {
      get() {
        return this.isEditMode;
      },
      set(value) {
        this.$emit("update:isEditMode", value);
      },
    },
    // isSkillRole() {
    //   // 기술담당자 여부
    //   return LocalStorageManager.shared.getIsSkill();
    // },
    // isCanAddManager() {
    //   return (
    //     (!this.isSkillRole && (this.isWriting || this.canAddOrChange) && !this.isAnnounceView) ||
    //     this.isFromPurchase
    //   );
    // },
  },
  methods: {
    onClickEditTagModeOn() {
      // 변경모드 켜기
      this.beforeDataList = this.isEditMode ? [] : this.dataListSync.concat();
      this.isEditModeSync = true;
    },
    onClickEditTagModeOff() {
      // 변경모드 취소
      this.dataListSync = this.beforeDataList;
      this.isEditModeSync = false;
      this.beforeDataList = [];
    },
    onClickEditTagCompleted() {
      // 변경모드 완료
      this.isEditModeSync = false;
      this.beforeDataList = [];
      if (this.canAddOrChange && !this.isFromPurchase) {
        this.$emit("onClickCallChangeManager");
      }
    },
    alert(text) {
      this.$emit("alert", text);
    },
  },
};
</script>
