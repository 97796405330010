const PopCatalogMixin = {
  data() {
    return {
      selectedCompany: null,
      selectedCatalog: null,
    };
  },
  methods: {
    onClickPopCatalog(item) {
      this.selectedCompany = item;

      this.selectedCatalog = null;
    },
    onClickCloseCatalogList() {
      this.selectedCompany = null;

      this.selectedCatalog = null;
    },
    onClickCatalogLine(item) {
      this.selectedCatalog = item;
    },
    onClickCloseCatalogDetail() {
      this.selectedCatalog = null;
    },
  },
};

export default PopCatalogMixin;
