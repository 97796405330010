import { mapState } from "vuex";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import ApiService from "@/services/ApiService";

const ClientStraightMixin = {
  data() {
    return {
      isPopClientStraight: false,
      clientData: {},
    };
  },
  computed: {
    ...mapState({
      isPurchaseMode: (state) => state.app.isPurchaseMode,
    }),
  },
  methods: {
    async onClickClientStraight(userNum) {
      const path = this.$apiPath.PARTNER + "?page=1&size=10000&all=n&confirm=n";
      const result = await ApiService.shared.getData(path);
      const { code, data, message } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
        this.clientData = data;
        this.isPopClientStraight = true;
        return;
      }
    },
    onClickCloseClientStraightPop() {
      this.isPopClientStraight = false;
    },
  },
};
export default ClientStraightMixin;
