import LocalStorageManager from "@/LocalStorageManager";
import EstimateGoodsListModel from "@/components/admin/estimate/write/EstimateGoodsListModel";

export default class EstimateModel {
  constructor() {
    this.itemId = "";
    this.objRaw = {};
    this.userData = LocalStorageManager.shared.getUserData();
    this.purchaseRequest = {};

    this.status = "T";
    this.regDate = "";
    this.reqName = "";
    this.name = ""; // 구매담당자 이름
    this.requester = ""; // 구매담당자 ID
    this.tel = "";
    this.handleNum = null;
    this.title = ""; //제목
    this.contents = ""; // 상세내용
    this.estimatePartnerList = []; //파트너사 리스트
    this.estimateItemList = [new EstimateGoodsListModel()];
    this.requestCid = null;
    this.purchaseManagerAccountId = "";
  }

  setLdapData(obj) {
    const { accountId, personName, employeeNo } = obj;

    this.purchaseManagerAccountId = `${accountId}`;
    this.purchaseManagerName = `${personName}`;
    this.purchaseManagerEmpNo = `${employeeNo}`;
  }

  setRequestCid(id) {
    this.requestCid = id;
  }

  setData(obj) {
    //수정일때 data SET

    this.objRaw = _.cloneDeep(obj);

    const {
      requestCid,
      status,
      regDate,
      reqName,
      name,
      buyerList,
      tel,
      email,
      handleNum,
      title,
      contents,
      estimatePartnerList,
      estimateItemList,
      upEstimateNum,
      upTitle,
      purchaseRequest,
      purchaseManagerEmpNo,
      purchaseManagerAccountId,
      purchaseManagerName,
    } = obj;

    this.requestCid = requestCid;
    this.purchaseRequest = purchaseRequest || {};
    this.status = status || "T";
    this.regDate = regDate || "";
    this.reqName = reqName || this.userData.name;
    this.name = name || this.userData.name;
    this.tel = tel || this.userData.tel;
    this.handleNum = handleNum;
    this.title = title || "";
    this.contents = contents || "";
    this.estimatePartnerList = estimatePartnerList || [];
    this.upEstimateNum = upEstimateNum || "";
    this.upTitle = upTitle || "";
    this.purchaseManagerEmpNo = purchaseManagerEmpNo || "";
    this.purchaseManagerAccountId = purchaseManagerAccountId || "";
    this.purchaseManagerName = purchaseManagerName || "";
    this.name = name || buyerList?.name || "";
    //this.estimateItemList = estimateItemList || [];
    if (estimateItemList && estimateItemList.length > 0) {
      this.estimateItemList = estimateItemList.map((item) => {
        return new EstimateGoodsListModel(item);
      });
    }
    //기타 obj 값 추가
  }

  setPurchaseRequest(obj) {
    const { purchaseRequestTitle, requestCid } = obj;
    this.purchaseRequest.title = purchaseRequestTitle;
    this.purchaseRequest.requestCid = requestCid;
  }

  setConvertPurchaseData(obj) {
    const {
      requestCid,
      title,
      assetType,
      requestDeptName,
      requesterId,
      requesterName,
      contents,
      buyerList,
      purchaseGoodsList,
      purchasePriceList,
    } = obj;

    const convertObj = {
      requestCid,
      title,
      estimateItemList: purchaseGoodsList.map((item) => {
        return {
          ...item,
          quantity: item.quantity,
          itemCode: item.itemCode,
        };
      }),
      purchaseRequest: {
        title: title,
        requestCid: requestCid,
      },
      buyerList: buyerList[0],
    };

    this.setData(convertObj);
  }

  getData() {
    //수정일때 data SET
    let obj = {
      status: this.status,
      reqName: this.userData.name,
      email: this.userData.email,
      name: this.name,
      tel: this.tel || this.userData.tel,
      handleNum: this.handleNum,
      title: this.title,
      contents: this.contents,
      estimatePartnerList: this.estimatePartnerList,
      estimateItemList: this.estimateItemList,
      requestCid: this.requestCid,
      purchaseManagerEmpNo: this.purchaseManagerEmpNo,
      purchaseManagerAccountId: this.purchaseManagerAccountId,
      purchaseManagerName: this.purchaseManagerName,
    };
    // return Object.assign({}, this.objRaw, obj);
    return Object.assign({}, obj);
  }

  addGood() {
    this.estimateItemList.push(new EstimateGoodsListModel());
  }
  removeGood(itemToRemove) {
    const removeGoodIndex = this.estimateItemList.indexOf(itemToRemove);
    this.estimateItemList = this.estimateItemList.filter((item) => item !== itemToRemove);
    this.estimateItemList.splice(removeGoodIndex, 0);
  }

  getAlertMessage() {
    if (!this.purchaseManagerAccountId) return "구매담당자를 입력해주세요.";
    if (!this.estimateItemList[0].itemCode) return "품목을 확인해주세요.";
    if (!this.title) return "견적요청명을 입력해주세요.";
    if (!this.estimatePartnerList || this.estimatePartnerList.length === 0)
      return "파트너사를 선택해주세요.";
    if (!this.contents) return "상세내용을 입력해주세요.";

    return null;
  }

  // setHandleItemAndCompanyList(handleItemNm, quotationSupplierList) {
  //   this.handleItemNm = handleItemNm;
  //   this.quotationSupplierList = quotationSupplierList;
  // }
}
