<template>
  <TableView>
    <template v-slot:colgroup>
      <!-- No -->
      <col style="width: 62px" />
      <!-- 품명 -->
      <col />
      <!-- 수량 -->
      <col style="width: 138px" />
      <!-- 추가/삭제 -->
      <col style="width: 86px" />
    </template>
    <template v-slot:header>
      <th scope="col">No.</th>
      <th scope="col">품명</th>
      <th scope="col">수량</th>
      <th scope="col">
        <button class="btn_small btn_secondary" @click="onClickAddGood">추가</button>
      </th>
    </template>
    <template v-slot:body>
      <EstimateGoodsListLine
        v-for="(item, index) in model.estimateItemList"
        :key="index"
        :estimateGoodsItem="model.estimateItemList[index]"
        :index="index"
        :item="item"
        :isDisabledRemove="model.estimateItemList.length === 1"
        @onClickSearchGoodsName="$emit('onClickSearchGoodsName', index)"
        @onClickRemove="onClickRemoveGood(item)"
      />
    </template>
    <!-- <template v-slot:foot>
      <div class="tbl_foot">
        <div class="area_total">
          <dl>
            <dt>총 기안금액</dt>
            <dd>{{ priceTotal | currency }}</dd>
          </dl>
        </div>
      </div>
    </template> -->
  </TableView>
</template>

<script>
import TableView from "@/components/shared/tableView/TableView";
import DateTimePicker from "@/components/common/calendar/DateTimePicker";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import SelectBox from "@/components/common/selectBox/SelectBox";

import EstimateGoodsListLine from "./EstimateGoodsListLine";

export default {
  name: "EstimateGoods",
  components: {
    TableView,
    EstimateGoodsListLine,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      currentList: [
        { code: "EA", name: "EA" },
        { code: "식", name: "식" },
        { code: "BOX", name: "Box" },
        { code: "직접입력", name: "직접입력" },
      ],
      currentSelected: { code: "EA", name: "EA" },
    };
  },
  computed: {
    isDirectMode() {
      return this.currentSelected.name == "직접입력";
    },
  },

  methods: {
    onClickAddGood() {
      this.model.addGood();
    },
    onClickRemoveGood(itemToRemove) {
      this.model.removeGood(itemToRemove);
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    selectCurrent(selectedCurrentObj) {
      this.currentSelected = selectedCurrentObj;
    },
  },
};
</script>
