<template>
  <Popup popupType="tableType" width="600px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">품명 조회</h3>
      </div>
      <div class="body_section">
        <FilterBarBase :btnBasic="false">
          <dl>
            <dt>검색어</dt>
            <dd>
              <div class="group_form">
                <SelectBox
                  style="width: 120px"
                  :dataList="keywordDatas"
                  :value.sync="keywordSelectedId"
                />
                <Input
                  style="width: 160px"
                  placeholder="검색어를 입력하세요"
                  :value.sync="searchKeyword"
                  @onEnterKey="onClickSearch"
                />
                <button type="submit" class="btn_medium btn_primary" @click="onClickSearch">
                  검색
                </button>
              </div>
            </dd>
          </dl>
        </FilterBarBase>
        <!-- 필터바 -->

        <!-- 품명 리스트 -->
        <Board
          :dataList="dataList"
          :isScroll="true"
          scrollHeight="calc(100vh - 544px)"
          scrollMaxHeight="294px"
          scrollMinHeight="49px"
          :showPagination="false"
        >
          <template v-slot:colgroup>
            <col style="width: 52px" />
            <col />
          </template>
          <template v-slot:tr>
            <th style="width: 52px" />
            <th style="width: 300px">분류</th>
            <th style="width: 248px">품명</th>
          </template>
          <template v-slot:rows>
            <template v-for="(item, index) in dataList">
              <tr :key="index" :class="{ tr_select: isSelected(item) }">
                <td>
                  <div class="box_choice type_alone">
                    <div class="item_choice">
                      <!-- disabled 상태시 disabled 클래스 추가 -->
                      <input
                        :id="item.categoryId"
                        v-model="selectedGoodsNameSync"
                        type="radio"
                        class="inp_choice"
                        name="selectedCorporateCard"
                        :value="item"
                      />
                      <label class="lab_choice" :for="item.itemCode">
                        <span class="ico_account ico_rdo" />
                      </label>
                    </div>
                  </div>
                </td>
                <td class="align_left">
                  {{ item.category | categoryText }}
                </td>
                <td class="align_left">
                  <Dialouge>
                    <template v-slot:dialogueHead>
                      <span class="txt_dialogue">{{ item.itemName }}</span>
                    </template>
                    <template v-slot:dialogueBody>
                      <strong class="tit_dialogue">{{ item.itemName }}</strong>
                      <dl class="info_dialogue">
                        <dt>분류</dt>
                        <dd>{{ item.category | categoryText }}</dd>
                        <dt>품목코드</dt>
                        <dd>{{ item.displayCode }}</dd>
                        <dt>단위</dt>
                        <dd>{{ item.unit | nullToDash }}</dd>
                        <dt>단가</dt>
                        <dd>{{ Number(item.price) | nullToDash | currency }}</dd>
                        <dt>제조사</dt>
                        <dd>{{ item.manufacturer | nullToDash }}</dd>
                        <dt>보증기간</dt>
                        <dd>{{ Number(item.warranty) ? `${item.warranty} 개월` : "-" }}</dd>
                        <dt>규격</dt>
                        <dd>{{ item.standard }}</dd>
                        <dt>품목상세</dt>
                        <dd v-html="noteDecoded"></dd>
                      </dl>
                    </template>
                  </Dialouge>
                </td>
              </tr>
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="2" class="td_empty">검색 결과가 없습니다</td>
            </tr>
          </template>
        </Board>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_medium btn_fourthly" @click.prevent="$emit('onClickClose')">
        취소
      </button>
      <button
        type="button"
        class="btn_medium btn_secondary"
        :disabled="isDisabledMoveBtn"
        @click="onClickSelected"
      >
        선택
      </button>
    </div>
  </Popup>
</template>
<script>
import Popup from "@/components/layout/popup/Popup";
import SelectBox from "@/components/common/selectBox/SelectBox";
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Board from "@/components/common/board/Board";
import Input from "@/components/common/input/Input";
import Dialouge from "@/components/common/dialouge/Dialouge.vue";
import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

export default {
  name: "PopGoodsNameSearch",
  components: {
    Popup,
    SelectBox,
    FilterBarBase,
    Input,
    Dialouge,
    Board,
  },
  mixins: [PageMixin],
  props: {
    selectedGoodsName: Object,
  },
  data() {
    return {
      keywordSelectedId: "itemName",
      searchKeyword: "",
      dataList: [],
      selectedGoodsNameSync: {}, // 선택한 품명 데이터
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedGoodsNameSync.itemCode;
    },
    keywordDatas() {
      const arr = [
        { id: "", code: "", desc: "선택" },
        { id: "displayCode", code: "displayCode", desc: "품목코드" },
        { id: "itemName", code: "itemName", desc: "품목명" },
      ];
      return arr;
    },

    noteDecoded() {
      if (!this.item?.note) {
        return "-";
      }
      const note = this.item.note;

      return escapeRegExp(note);
    },
  },

  created() {
    this.$store.dispatch(PROGRESS_START_ACTION);
    this.getDataList();
    this.selectedGoodsNameSync = this.selectedGoodsName;
    // this.getDeptInfo();
  },
  methods: {
    async getDataList() {
      const path = `${this.$apiPath.ITEM}?listType=L&${this.keywordSelectedId}=${this.searchKeyword}`;
      //const path = `${this.$apiPath.CMDB_CATEGORY}?categoryName=${name}`;

      const result = await ApiService.shared.getData(path);
      this.$store.dispatch(PROGRESS_END_ACTION);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.dataList = data;
    },
    onClickSearch() {
      this.getDataList();
    },
    isSelected(item) {
      return this.selectedGoodsNameSync.itemCode == item.itemCode;
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedGoodsNameSync);
    },
  },
};
</script>
