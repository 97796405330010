<template>
  <Popup popupType="tableType" width="980px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">카탈로그상세</h3>
      </div>
      <div class="body_section">
        <TableView style="overflow-y: auto; max-height: 540px; height: calc(100vh - 266px)">
          <template v-slot:colgroup>
            <col style="width: 191px" />
            <col style="width: 710px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>카탈로그명</th>
              <td class="td_text">
                {{ itemData.brochureTitle }}
              </td>
            </tr>
            <tr>
              <th>등록일자</th>
              <td class="td_text">
                {{ itemData.createDt | dateStringFormat }}
              </td>
            </tr>
            <tr>
              <th>상세내용</th>
              <td v-html="itemData.brochureContents" />
            </tr>
            <tr v-if="itemData.brochureFileNm1">
              <th>카탈로그첨부</th>
              <td v-if="isImageFile">
                <img class="img_file" :src="imageUrl" style="max-width: 662px" />
              </td>
              <td v-else>
                <FileView :dataList="catalogFileList" />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_tertiary btn_medium" @click="onClickClose">닫기</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import FileView from "@/components/common/file/FileView";

import ApiService from "@/services/ApiService";
import { ALERT_ACTION } from "@/store/modules/alert/action";

import CatalogMixin from "@/mixins/CatalogMixin";

export default {
  components: {
    Popup,
    TableView,
    FileView,
  },
  mixins: [CatalogMixin],
  props: {
    brochureSeq: Number,
    widthPopScore: String,
  },
  data() {
    return {
      itemData: {},
    };
  },
  computed: {
    catalogFileList() {
      const { brochureFileSeq1, brochureFileNm1, brochureFileEx1, brochureFileSysPath } =
        this.itemData;

      if (!brochureFileSysPath || !brochureFileNm1) return [];

      const obj = {
        fileName: brochureFileNm1,
        fileExt: brochureFileEx1,
        sysFilePath: brochureFileSysPath,
      };

      return [obj];
    },
  },
  beforeMount() {
    this.getData(this.brochureSeq);
  },
  methods: {
    async getData(id) {
      const path = `${this.$apiPath.BROCHURE}/${id}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, message } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);

        return;
      }

      this.itemData = data;
      // this.getFileImg(this.itemData.brochureFileSeq1);

      if (!this.isImageFile) return;

      const { brochureFileSysPath } = data;

      this.getCatalogFileUrl(brochureFileSysPath);
    },

    onClickClose() {
      this.$emit("onClickClose");
    },
  },
};
</script>
