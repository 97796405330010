<template>
  <ul class="list_tag_ldap">
    <li v-for="item in dataList" :key="item.loginId">
      <button
        v-if="!isViewMode || isEditMode"
        type="button"
        class="btn_tag_ldap"
        @click="onClickTag(item)"
      >
        {{ item | idWithNameByObj }}
        <span v-if="isEditMode" class="ico_purchase ico_edit_tag">변경</span>
        <span v-else class="ico_purchase ico_cross3">삭제</span>
      </button>
      <span v-else class="txt_tag">{{ item | idWithNameByObj }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  name: "LdapTagList",
  props: {
    dataList: Array,
    isViewMode: {
      type: Boolean,
      defaulf: false,
    },
    isEditMode: Boolean,
  },
  methods: {
    onClickTag(item) {
      if (this.isEditMode) {
        this.$emit("onClickEditTag", item);
      } else {
        this.$emit("onClickRemoveTag", item);
      }
    },
  },
};
</script>
