<template>
  <div ref="container" class="area_search">
    <span v-if="icon" class="ico_purchase ico_search" />
    <Input
      ref="input"
      :placeholder="placeholder"
      :value.sync="input"
      :maxLength="maxLength"
      @update:focus="onFocus"
      @update:blur="onBlur"
      @onInput="onInput"
      @onEnterKey="onEnterKey"
    />
    <!-- v-on:keyup.13="onKeyUpEnter" -->
    <div v-show="hasFocus" class="box_drop" :style="isMaxHeight">
      <button
        v-for="item in dataList"
        :key="item.id"
        type="button"
        class="btn_drop"
        @mousedown="onMouseDownItem"
        @click="(e) => onClickItem(e, item)"
      >
        <strong class="tit_drop">{{ item }}</strong>
      </button>
      <p v-if="!dataList || dataList.length < 1" class="desc_empty">검색결과가 없습니다</p>
    </div>
  </div>
</template>

<script>
import Input from "@/components/common/input/Input";

export default {
  name: "Search",
  components: {
    Input,
  },
  props: {
    dataList: Array,
    value: String,
    placeholder: String,
    dropBoxMaxHeight: String,
    icon: {
      type: Boolean,
      default: false,
    },
    maxLength: Number,
  },
  data() {
    return {
      hasFocus: false,
    };
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    isMaxHeight() {
      if (this.dropBoxMaxHeight) {
        return "max-height:" + this.dropBoxMaxHeight;
      } else {
        return "";
      }
    },
  },
  methods: {
    onMouseDownItem(e) {
      e.preventDefault();
    },
    onClickItem(e, item) {
      this.$emit("setValue", item);
      // Input 컴포넌트의 blur 메서드 호출.
      this.$refs.input.blur();
    },
    onFocus(e) {
      this.hasFocus = true;

      this.$emit("onFocus", this.value);
    },
    onBlur(e) {
      this.hasFocus = false;
    },
    async query(value) {
      this.$emit("onInputKeyword", value);
    },
    debouncedQuery: _.debounce(function (value) {
      this.query(value);
    }, 300),
    onInput(e) {
      const strTrim = e.target.value.trim();

      this.debouncedQuery(strTrim);
    },
    onEnterKey(e) {
      this.$refs.input.blur();
      this.$emit("onEnterKey", e);
    },
  },
};
</script>

<style scoped>
.area_search {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
}
.area_search .ico_search + .tf_comm input {
  padding-right: 38px;
}
.area_search .ico_search {
  position: absolute;
  top: 9px;
  right: 11px;
}
.desc_empty {
  font-size: 12px;
}
</style>
