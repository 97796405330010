export function assign(arrPrev, arrNew, key) {
  const arrValid = arrNew.filter(
    (itemNew) =>
      !arrPrev.some((itemPrev) => {
        if (!key) {
          return itemPrev === itemNew;
        }

        return itemPrev[key] === itemNew[key];
      }),
  );

  return arrPrev.concat(arrValid);
}
