<template>
  <tr :class="{ tr_select: isSelected, tr_disabled: isDisabled }" @click="onClickSelect">
    <td class="td_ellip align_left" :data-ellip="rowData.personName || rowData.displayName">
      {{ rowData.personName || rowData.displayName }}
    </td>
    <td class="align_left">
      {{ rowData.accountId }}
    </td>

    <td class="td_ellip align_left" :data-ellip="`${rowData.deptName}`">
      {{ `${rowData.deptName}` }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "LdapPopupLine",
  props: {
    rowData: Object,
    isSelected: Boolean,
    isDisabled: Boolean,
  },
  methods: {
    onClickSelect() {
      if (!this.isDisabled) {
        this.$emit("onClickSelectItem", this.rowData);
      }
    },
  },
};
</script>
