<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">견적관리</h2>
      </div>
      <div class="body_section">
        <Fold title="기본정보">
          <TableView>
            <template v-slot:body>
              <tr>
                <th>견적요청서번호</th>
                <td>{{ model.itemId || "-" }}</td>
                <th>견적요청일자</th>
                <td>{{ model.regDate | dateStringFormat }}</td>
              </tr>
              <tr>
                <th>견적요청작성자</th>
                <td>{{ model.userData.username }} ({{ model.userData.name }})</td>
                <th>구매 담당자 <span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <div class="">
                    <button
                      type="button"
                      class="btn_fourthly btn_small"
                      @click="onClickPopLdapRequester"
                    >
                      조직도 검색
                    </button>
                    <button
                      v-if="model.purchaseManagerAccountId"
                      type="button"
                      class="btn_tag_ldap"
                      @click="onClickRemoveRequester"
                    >
                      {{ `${model.purchaseManagerAccountId} (${model.purchaseManagerName})` }}
                      <span class="ico_purchase ico_cross3">삭제</span>
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>견적요청명 <span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <Input
                    placeholder="견적요청명을 입력하세요."
                    :maxLength="MAX_LENGTH_TITLE"
                    :value.sync="model.title"
                  />
                </td>
                <th>이전견적</th>
                <td>
                  <button
                    :class="['link_subject']"
                    @click="$windowOpen(`${$routerPath.ESTIMATE_VIEW}/${model.upEstimateNum}`)"
                    v-html="model.upTitle"
                  />
                </td>
              </tr>
              <tr>
                <th>구매요청서 정보<span class="ico_purchase ico_star">필수항목</span></th>
                <td colspan="3" style="height: 73px">
                  <div class="group_form">
                    <button
                      type="button"
                      class="btn_fourthly btn_small"
                      @click="onClickPurchaseRequestsSearch"
                    >
                      구매요청 정보 불러오기
                    </button>
                  </div>
                  <ConnectEditOnlyOne
                    :dataList="model.purchaseRequest"
                    @onClickConnect="
                      (item) => {
                        $windowOpen(`${$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
                      }
                    "
                    @onClickRemove="onClickRemovePurchaseReqeust"
                  />
                </td>
              </tr>
            </template>
          </TableView>
        </Fold>
        <Fold title="견적상세품목" :isEssential="true">
          <EstimateGoods :model="model" @onClickSearchGoodsName="onClickSearchGoodsName" />
        </Fold>

        <Fold title="파트너사 조회" :isEssential="true">
          <RequestPartnersInfo
            :disabled="sendCompleted"
            :postPersonList="model.estimatePartnerList"
            @onClickPopPartner="onClickPopPartner"
            @onClickRemoveItem="onClickRemoveItem"
          />
        </Fold>

        <Fold title="상세내용">
          <TableView>
            <template v-slot:body>
              <tr>
                <th>상세내용 <span class="ico_purchase ico_star">필수항목</span></th>
                <td style="height: 440px" colspan="3">
                  <Textarea
                    placeholder="상세정보를 입력하세요.

  1. 품목명 : A 품목
  2. 규격 : 화이트
  3. 수량 : 1,000EA
  4. 희망 단가 : 1,000원
  5. 비고 :"
                    :maxLength="MAX_LENGTH_DETAIL"
                    :isCount="true"
                    :value.sync="model.contents"
                  />
                </td>
              </tr>
            </template>
          </TableView>
        </Fold>
      </div>
    </div>

    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">취소</button>
        <button
          v-if="isModifyMode && !sendCompleted"
          type="button"
          class="btn_fifthly btn_large"
          @click="onClickRemove"
        >
          삭제
        </button>
      </div>
      <div class="area_right">
        <button type="button" class="btn_secondary btn_large" @click="onClickSave(true)">
          임시저장
        </button>
        <button
          v-if="!sendCompleted"
          type="button"
          class="btn_primary btn_large"
          @click="onClickSaveConfirm"
        >
          발송
        </button>
        <button
          v-if="sendCompleted"
          type="button"
          class="btn_primary btn_large"
          @click="onClickResaveConfirm"
        >
          재견적
        </button>
      </div>
    </Sticky>

    <template v-slot:popup>
      <AnnouncePartnerPopup
        v-if="isPopPartners"
        @onClickClose="onClickClosePopPartner"
        @onClickAdd="onClickAddPopPartner"
      />
      <PopGoodsNameSearch
        v-if="isPopGoodsNameSearch"
        :selectedGoodsName="selectedGoodsName"
        @onClickClose="onClickClosePopGoodsNameSearch"
        @onClickComplete="onClickCompletePopGoodsNameSearch"
      />

      <AlertPopup
        v-if="isSaveConfirm"
        alertText="견적을 발송하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="saveEstimate"
      />

      <AlertPopup
        v-if="isResaveConfirm"
        alertText="재견적을 발송하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="saveEstimate"
      />

      <AlertPopup
        v-if="isUpdateConfirm"
        alertText="견적을 수정하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="saveEstimate"
      />
      <AlertPopup
        v-if="isTempConfirm"
        alertText="견적을 임시서장하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="saveEstimate"
      />
      <AlertPopup
        v-if="isAlertCancel"
        alertText="견적 작성을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="onClickGoToList"
      />
      <LdapPopup
        v-if="isPopLdapRequester"
        :isManager="'Y'"
        @onClickSelect="onClickSelect"
        @onClickClose="onClickClosePopLdap"
        @alert="alert"
      />
      <LdapMultiplePopup
        v-if="isPopLdapMultiple"
        :isManager="'Y'"
        :selectedDataList="model.buyerList"
        :disabledDataList="disabledDataList"
        @onClickSelect="onClickSelectLdapMultiple"
        @onClickClose="onClickClosePopLdapManager"
        @alert="alert"
      />
      <!-- <ClientStraightPopup
        v-if="isPopClientStraight"
        :clientData="clientData"
        @onClickClose="onClickCloseClientStraightPop"
        @setData="onClickSupplySearch"
      />
      <ScorePopup
        v-if="isPopScore"
        :itemData="companyCreditData"
        @onClickClose="onClickCloseScorePop"
      />
      <CatalogListPopup
        v-if="selectedCompany"
        v-show="!selectedCatalog"
        :companyNo="
          selectedCompany
            ? selectedCompany.spplrCompanyRgstNo || selectedCompany.clientCompanyRgstNo
            : null
        "
        @onClickCatalogLine="onClickCatalogLine"
        @onClickClose="onClickCloseCatalogList"
      />
      <CatalogDetailPopup
        v-if="selectedCompany && selectedCatalog"
        :brochureSeq="selectedCatalog.brochureSeq"
        @onClickClose="onClickCloseCatalogDetail"
      /> -->
      <PurchaseRequestsPopup
        v-if="isPopPurchaseRequests"
        @onClickClose="onClickClosePopPurchaseRequests"
        @setPurchaseContract="setPurchaseContract"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";

import TableView from "@/components/shared/tableView/TableView";
import Fold from "@/components/common/fold/Fold";
import Board from "@/components/common/board/Board";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import Input from "@/components/common/input/Input";
import ManagerLdap from "@/components/shared/managerLdap/ManagerLdap";
import Radio from "@/components/common/radio/Radio";
import Textarea from "@/components/common/textarea/Textarea";
import Sticky from "@/components/layout/content/Sticky.vue";
import LdapPopup from "@/components/layout/popup/ldapPopup/LdapPopup";
import LdapMultiplePopup from "@/components/layout/popup/ldapPopup/LdapMultiplePopup";
import ClientStraightPopup from "@/components/layout/popup/ClientStraightPopup";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ScorePopup from "@/components/admin/estimate/popup/ScorePopup";
import CatalogListPopup from "@/components/admin/estimate/popup/CatalogListPopup";
import CatalogDetailPopup from "@/components/admin/estimate/popup/CatalogDetailPopup";

import RequestPartnersInfo from "@/components/admin/announce/write/RequestPartnersInfo";
import EstimateGoods from "@/components/admin/estimate/write/EstimateGoods.vue";

import PopGoodsNameSearch from "@/components/admin/draft/write/popup/PopGoodsNameSearch.vue";
import AnnouncePartnerPopup from "@/components/admin/announce/popup/AnnouncePartnerPopup";

import WritePageMixin from "@/mixins/WritePageMixin";
import CreditMixin from "@/mixins/CreditMixin";
import PopCatalogMixin from "@/mixins/estimate/PopCatalogMixin";
import ClientStraightMixin from "@/mixins/ClientStraightMixin";
import PageMixin from "@/mixins/PageMixin";
import ManagerLdapMixin from "@/mixins/ManagerLdapMixin";

import ApiService from "@/services/ApiService";
import { mapState } from "vuex";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { GET_ITEM_CATEGORY_ACTION } from "@/store";

import { getMoneyToNumber } from "@/utils/stringUtils";
import { dateToString } from "@/utils/dateUtils";
import { assign } from "@/utils/arrayUtils";
import { makePathWithQuery } from "@/utils/urlUtils";

import EstimateModel from "./EstimateModel";
import EstimateGoodsListModel from "@/components/admin/estimate/write/EstimateGoodsListModel";
import PurchaseRequestsPopup from "@/components/shared/purchaseRequestPopup/PurchaseRequestsPopup";
import ConnectEditOnlyOne from "@/components/common/connect/ConnectEditOnlyOne";
import ReferenceMixin from "@/mixins/ReferenceMixin";
import SupplyInfoItemModel from "@/components/admin/order/write/SupplyInfoItemModel";

// import { ESTIMATE_WRITE_ACTION } from '@/store/modules/estimate/action';

export default {
  name: "EstimateWrite",
  components: {
    ConnectEditOnlyOne,
    PurchaseRequestsPopup,
    PageWithLayout,
    AlertPopup,
    TableView,
    Input,
    ManagerLdap,
    Fold,
    Board,
    CheckboxItem,
    Textarea,
    Sticky,
    ScorePopup,
    Radio,
    PopGoodsNameSearch,
    RequestPartnersInfo,
    AnnouncePartnerPopup,
    EstimateGoods,

    CatalogListPopup,
    CatalogDetailPopup,
    ClientStraightPopup,
    LdapPopup,
    LdapMultiplePopup,
  },
  mixins: [
    WritePageMixin,
    CreditMixin,
    PopCatalogMixin,
    ClientStraightMixin,
    PageMixin,
    ManagerLdapMixin,
    ReferenceMixin,
  ],
  data() {
    return {
      model: new EstimateModel(),

      isModifyMode: false,
      isPopPartners: false,
      isPopGoodsNameSearch: false,
      isAlertCancel: false,
      isTempConfirm: false,
      isSaveConfirm: false,
      isResaveConfirm: false,
      isUpdateConfirm: false,
      isRemoveConfirm: false,

      goodsNameTargetIndex: null,
      selectedGoodsName: {},
      isPopLdapRequester: false,

      sendCompleted: false,

      isPopPurchaseRequests: false,
      purchaseWfOrder: {},

      // itemData: {},
      // searchType: '',
      // inputSearchText: '',
      // searchedCategories: [], // 현재 sort 된 품목군 리스트
      // supplyCompanyDataList: [], // 검색한 공급사 리스트.
      // checkedNames: [],
      // checkNamesAll: [], // 전체 체크 때문에 필요.
      // handleItemNm: null,
      // companyCreditData: null,
      // isClientMode: true,
    };
  },
  computed: {
    managerLdapDataList: {
      get() {
        return this.model.buyerList;
      },
      set(value) {
        this.model.buyerList = value;
      },
    },
    toPath() {
      const { upEstimateNum } = this.model;
      const path = this.$routerPath.ESTIMATE_VIEW;
      const ret = makePathWithQuery(path, upEstimateNum, this.$route.query);
      return ret;
    },
  },
  created() {
    this.isModifyMode = Boolean(this.$route.meta.isModifyMode);
    this.model.itemId = this.$route.params.id;

    if (this.isModifyMode) {
      // this.$store.dispatch(ESTIMATE_WRITE_ACTION);
      this.getData();
    } else {
      const { requestCid, type } = this.$route.params;
      if (requestCid) {
        const { requestCid, type } = this.$route.params;
        this.requestCid = requestCid;
        this.type = type;
        this.getPurchaseRequest();
      }
    }
  },
  methods: {
    async getPurchaseRequest() {
      // 구매요청상세에서 계약관리 버튼 눌어 들어 왓을떄에 데이터 처리
      // 구매요청서 선택시 데이터 처리
      const path = `${this.$apiPath.ST_COMM_REQUEST}/${this.type}/${this.requestCid}`;
      const result = await ApiService.shared.getData(`${path}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.model.setConvertPurchaseData(data.purchaseRequestDetail);
    },
    async getData() {
      const path = `${this.$apiPath.ESTIMATE}/${this.model.itemId}`;
      const result = await ApiService.shared.getData(path);

      const { code, data, text } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, text);
        return;
      }
      this.model.setData(data);

      if (!this.model.purchaseRequest.title) {
        this.model.setPurchaseRequest(data);
      }
      const { status } = this.model;

      this.sendCompleted = status == "S" ? true : false;
    },
    async onClickSave(isTemp) {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      if (isTemp) {
        this.model.status = "T"; //임시저장
      } else {
        this.model.status = "C"; //저장
      }
      this.saveEstimate();
    },
    async saveEstimate() {
      const obj = this.model.getData();
      if (this.isModifyMode && !this.sendCompleted) {
        //수정
        const path = `${this.$apiPath.ESTIMATE}`;
        const { id } = this.$route.params;

        const result = await ApiService.shared.putData(`${path}/${id}`, obj);
        const { code, data, text } = result;

        if (code !== "200") {
          this.alert(text);
          return;
        }
      } else if (this.sendCompleted) {
        // 재견적
        const path = `${this.$apiPath.ESTIMATE_RE}`;
        obj.upEstimateNum = this.model.itemId;
        const result = await ApiService.shared.postData(path, obj);
        const { code, data, text } = result;
        if (code !== "200") {
          this.alert(text);
          return;
        }
      } else {
        //발송
        const path = `${this.$apiPath.ESTIMATE}`;
        const result = await ApiService.shared.postData(path, obj);
        const { code, data, text } = result;
        if (code !== "200") {
          this.alert(text);
          return;
        }
      }

      this.goToList();
    },
    onClickPopLdapRequester() {
      this.isPopLdapRequester = true;
    },
    onClickRemoveRequester() {
      const emptyObj = { accountId: "", personName: "", employeeNo: "" };
      this.model.setLdapData(emptyObj);
    },
    onClickClosePopLdap() {
      this.isPopLdapRequester = false;
    },
    onClickSelect(rowData) {
      this.model.setLdapData(rowData);
      this.isPopLdapRequester = false;
    },

    onClickPopPartner() {
      this.isPopPartners = true;
    },
    onClickRemoveItem(itemToRemove) {
      this.model.estimatePartnerList = this.model.estimatePartnerList.filter(
        (item) => item !== itemToRemove,
      );
    },
    onClickClosePopPartner() {
      this.isPopPartners = false;
    },
    onClickAddPopPartner(checkedNames) {
      this.model.estimatePartnerList = assign(
        this.model.estimatePartnerList,
        checkedNames,
        "personNum",
      );

      this.isPopPartners = false;
    },
    onClickSearchGoodsName(index) {
      this.isPopGoodsNameSearch = true;
      this.goodsNameTargetIndex = index;
    },

    onClickClosePopGoodsNameSearch() {
      this.isPopGoodsNameSearch = false;
      this.goodsNameTargetIndex = null;
      this.selectedGoodsName = {};
    },
    onClickCompletePopGoodsNameSearch(selectedGoodsName) {
      this.isPopGoodsNameSearch = false;
      const target = this.model.estimateItemList[this.goodsNameTargetIndex];
      target.setItemData(selectedGoodsName);
    },
    onClickRemove() {
      const funcRemove = async () => {
        const { itemId } = this.model;
        const path = `${this.$apiPath.ESTIMATE}/${itemId}`;

        const result = await ApiService.shared.deleteData(path);

        const { code, message } = result;

        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);

          this.goToList();
          return;
        }
        this.goToList();
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: "견적요청을 삭제하시겠습니까?",
        onClickY: funcRemove,
      });
    },

    onClickGoToList() {
      this.goToList();
    },
    onClickCancelConfirm() {
      this.isAlertCancel = true;
    },
    onClickSaveConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isSaveConfirm = true;
      this.model.status = "S";
    },

    onClickResaveConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isResaveConfirm = true;
      this.model.status = "S";
    },

    onClickUpdateConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isUpdateConfirm = true;
      this.model.status = "S";
    },
    onClickTempConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isTempConfirm = true;
      this.model.status = "T";
    },
    onClickRemoveConfirm() {
      this.isRemoveConfirm = true;
    },
    onClickAlertCancel() {
      this.isAlertCancel = false;
    },
    onClickCloseAlertConfirm() {
      this.isSaveConfirm = false;
      this.isResaveConfirm = false;
      this.isUpdateConfirm = false;
      this.isTempConfirm = false;
      this.isRemoveConfirm = false;
      this.isAlertCancel = false;
    },
    goToList() {
      this.$router.push(this.$routerPath.ESTIMATE_LIST);
    },

    // onClickedAll(arrCheckedAll) {
    //   if (arrCheckedAll.length > 0) {
    //     this.checkedNames = this.supplyCompanyDataList.map((item) => item.spplrCompanyRgstNo);
    //     this.checkNamesAll = ['allChecked'];
    //   } else {
    //     this.checkNamesAll = [];
    //     this.checkedNames = [];
    //   }
    // },
    // onClickedItem() {
    //   this.checkNamesAll = [];
    //   if (
    //     this.supplyCompanyDataList != undefined &&
    //     this.checkedNames.length === this.supplyCompanyDataList.length
    //   ) {
    //     this.checkNamesAll = ['allChecked'];
    //   } else {
    //     this.checkNamesAll = [];
    //   }
    // },

    // onClickPopScore(rowData) {
    //   const companyNo = rowData.spplrCompanyRgstNo || rowData.clientCompanyRgstNo;
    //   this.getCreditData(companyNo);
    // },

    // onClickSupplySearch() {
    //   if (this.isClientMode) {
    //     this.getClient();
    //     this.searchType = 'client';
    //     return;
    //   }

    //   if (!this.handleItemNm) {
    //     this.alert('품목군을 선택해주세요.');
    //     return;
    //   }

    //   this.getCompanyList(this.handleItemNm);
    //   this.searchType = 'handleItem';
    // },
    // async getClient(strKeyword) {
    //   let urlRest = '';

    //   if (strKeyword) {
    //     const obj = {
    //       clientName: strKeyword,
    //     };

    //     urlRest = makeQueryStringByObject(obj);
    //   }

    //   const path = `${this.$apiPath.COMPANY_CLIENT}${urlRest}`;
    //   const result = await ApiService.shared.getData(path);

    //   const { data, code, message } = result;

    //   if (code !== '200') {
    //     this.alert(message);
    //     return;
    //   }
    //   this.supplyCompanyDataList = data || [];
    //   this.supplyCompanyDataList.map((item) => {
    //     item.clientYn = true;
    //   });

    //   this.model.setHandleItemAndCompanyList(null, []);
    // },
    // async getCompanyList(itemCategoryNm) {
    //   const path = `${this.$apiPath.SUPPLIER_SEARCH}?itemName=${encodeURIComponent(
    //     itemCategoryNm,
    //   )}`;
    //   this.supplyCompanyDataList = [];
    //   const result = await ApiService.shared.getData(path);

    //   const { data, code, message } = result;

    //   if (code !== '200') {
    //     this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
    //     return;
    //   }

    //   this.inputSearchText = itemCategoryNm;

    //   this.checkedNames = [];

    //   this.supplyCompanyDataList = data;

    //   this.model.setHandleItemAndCompanyList(itemCategoryNm, []);
    // },
    // // 품목군 입력하면서 sort.
    // onInputKeywordCategory(value) {
    //   // if(value == ''){
    //   //   this.supplyCompanyDataList = undefined;
    //   // }
    //   this.searchedCategories = this.itemCategoryList.filter((item) =>
    //     item.itemCategoryNm.toUpperCase().includes(value.toUpperCase()),
    //   );
    // },
    // 구매요청서 정보 불러오기
    onClickPurchaseRequestsSearch() {
      this.isPopPurchaseRequests = true;
    },
    // 구매요청서 선택 팝업 닫기
    onClickClosePopPurchaseRequests() {
      this.isPopPurchaseRequests = false;
    },
    onClickConnectPurchaseWfPost(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },
    // 구매요청서 항목 삭제
    onClickRemovePurchaseReqeust() {
      this.model = new EstimateModel();
    },
    setPurchaseContract(item) {
      this.type = "purchase";
      this.requestCid = item.requestCid;
      this.getPurchaseRequest();
    },
  },
};
</script>
