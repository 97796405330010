<template>
  <CalendarBase>
    <el-date-picker
      v-model="input"
      type="datetime"
      format="yyyy-MM-dd HH:mm"
      :placeholder="placeholder"
      :disabled="isDisabled"
    />
    <span class="ico_purchase ico_calendar" />
  </CalendarBase>
</template>

<script>
import CalendarBase from "./CalendarBase";

export default {
  name: "DateTimePicker",
  components: {
    CalendarBase,
  },
  props: {
    date: [Date, String],
    placeholder: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        return this.date;
      },
      set(value) {
        this.$emit("update:date", value);
      },
    },
  },
};
</script>
