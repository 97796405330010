import PageMixin from "./PageMixin";

import {
  MAX_LENGTH_TITLE,
  MAX_LENGTH_DETAIL,
  MAX_LENGTH_MEMO,
  MAX_LENGTH_MANAGER_NAME,
} from "@/constants/commonTextLength";

const WritePageMixin = {
  mixins: [PageMixin],
  computed: {
    MAX_LENGTH_TITLE() {
      return MAX_LENGTH_TITLE;
    },
    MAX_LENGTH_DETAIL() {
      return MAX_LENGTH_DETAIL;
    },
    MAX_LENGTH_MEMO() {
      return MAX_LENGTH_MEMO;
    },
    MAX_LENGTH_MANAGER_NAME() {
      return MAX_LENGTH_MANAGER_NAME;
    },
  },
};
export default WritePageMixin;
