import { ALERT_ACTION } from "@/store/modules/alert/action";

const CreditMixin = {
  data() {
    return {
      isPopScore: false,
      companyCreditData: null,
    };
  },
  methods: {
    getCreditData(companyNo) {
      const path = this.$apiPath.getSupplierCredit(companyNo);

      http.get(path).then((response) => {
        const { code, data, message } = response.data;

        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);

          return;
        }

        this.companyCreditData = data;

        this.isPopScore = true;
      });
    },
    onClickCloseScorePop() {
      this.isPopScore = false;

      this.companyCreditData = null;
    },
  },
};

export default CreditMixin;
