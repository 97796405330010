<template>
  <FilterBarBase :btnBasic="false">
    <dl>
      <dt>이름/사번 검색</dt>
      <dd class="w100p">
        <div class="group_form">
          <Input
            class="w282"
            :value.sync="inputCurrentText"
            :isFocusOnMount="true"
            @onEnterKey="onClickSearch"
          />
          <div class="area_right">
            <button type="submit" class="btn_medium btn_primary" @click="onClickSearch">
              검색
            </button>
          </div>
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";

export default {
  name: "LdapPopupFilterBar",
  components: {
    FilterBarBase,
    Input,
  },
  data() {
    return {
      inputData: {
        placeholder: null,
      },
      inputCurrentText: "",
    };
  },
  methods: {
    onClickSearch(e) {
      // if (!this.inputCurrentText.trim()) {
      //   this.alert('내용을 입력해주세요.');
      //   return;
      // }

      const ret = {
        text: this.inputCurrentText,
      };
      this.$emit("onClickSearch", ret);
    },
    alert(text) {
      this.$emit("alert", text);
    },
  },
};
</script>
