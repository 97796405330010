import FileMixin from "@/mixins/FileMixin";

const CatalogMixin = {
  mixins: [FileMixin],
  data() {
    return {
      itemData: {},

      imageUrl: null,
    };
  },
  computed: {
    isImageFile() {
      const { fileExt, brochureFileEx1 } = this.itemData;

      return this.getIsImage(fileExt || brochureFileEx1);
    },
  },
  methods: {
    getIsImage(strExt) {
      return strExt === "jpg" || strExt === "jpeg" || strExt === "png";
    },
    async getCatalogFileUrl(fileSeq) {
      const url = await this.getFileUrl(fileSeq);

      this.imageUrl = url;

      // const path = `${this.$apiPath.ATTACH_PREVIEW}/${fileSeq}`;

      // http.get(path).then(response => {
      //   const { code, data, message } = response.data;

      //   if (code !== '200') {
      //     alert(message);
      //     return;
      //   }

      //   this.imageUrl = message;
      // });
    },
  },
};

export default CatalogMixin;
