<template>
  <FilterBarBase btnSize="medium" @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox
            class="w152"
            :dataList="radioData.dataList"
            :value.sync="radioData.selectedId"
          />
          <Search
            class="w588"
            dropBoxMaxHeight="162px"
            placeholder="검색어를 입력해주세요"
            :dataList="autoDataList"
            :value.sync="inputCurrentText"
            @setValue="onSetValue"
            @onEnterKey="onClickSearch"
            @onInputKeyword="onInputKeyword"
          />
          <!-- @update:value="onInputUpdate" -->
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Radio from "@/components/common/radio/Radio";
import Search from "@/components/common/search/Search";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "AnnouncePartnerPopupFilterBar",
  components: {
    FilterBarBase,
    Radio,
    Search,
    SelectBox,
  },
  props: {
    autoDataList: Array,
    defaultStatus: String,
  },
  data() {
    return {
      status: this.defaultStatus,
      radioData: {
        name: "announceListRadio0",
        dataList: [
          { code: "companyName", desc: "업체명" },
          { code: "name", desc: "파트너사 담당자" },
          { code: "job", desc: "담당업무" },
          // { code: 'corporateNum', desc: '사업자 등록 번호' },
          { code: "categoryItem", desc: "취급품목" },
        ],
        selectedId: "companyName",
      },
      inputCurrentText: "",
    };
  },
  methods: {
    onSetValue(item) {
      console.log(item);
      this.inputCurrentText = item;
    },
    onClickSearch(item) {
      if (!this.inputCurrentText.trim()) {
        return;
      }

      const ret = {
        radioSelectedId: this.radioData.selectedId,
        inputCurrentText: this.inputCurrentText,
      };
      this.$emit("onClickSearch", ret);
    },
    onInputKeyword(value) {
      const obj = {
        radioSelectedId: this.radioData.selectedId,
        inputCurrentText: value,
      };

      this.$emit("onAutoComplete", obj);
    },
    onClickReset() {
      this.radioData.selectedId = "companyName";
      this.inputCurrentText = "";
    },
  },
};
</script>
