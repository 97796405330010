<template>
  <Popup popupType="tableType" width="877px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">기업신용평가정보</h3>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 429px" />
            <col style="width: 192px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>회사명</th>
              <td class="td_text">
                {{ itemData.companyNm || "-" }}
              </td>
              <th class="align_center">기업평가신용등급</th>
            </tr>
            <tr>
              <th>대표자명</th>
              <td>{{ itemData.representativeNm || "-" }}</td>
              <td rowspan="3" class="align_center align_mid">
                <span :class="['ico_grade', 'ico_' + gradeType]" />
                <span v-if="gradeType == 'except'" class="txt_except">평가등급이 없습니다</span>
                <span v-else class="txt_score"
                  >{{ itemData.createPoint }}<span class="txt_unit">점</span></span
                >
              </td>
            </tr>
            <!-- <tr>
              <th>법인등록번호</th>
              <td>{{ itemData.registrationNum || '' }}</td>
            </tr>-->
            <tr>
              <th>사업자등록번호</th>
              <td>{{ itemData.companyRgstNo || "-" }}</td>
            </tr>
            <tr>
              <th>회사전화번호</th>
              <td>{{ itemData.telNo || "-" }}</td>
            </tr>
            <!-- <tr>
              <th>주소</th>
              <td class="td_text">{{ addressFull }}</td>
            </tr>-->
            <!-- <tr>
              <th>재무결산기준일</th>
              <td>{{ itemData.regDate }}</td>
            </tr>
            <tr>
              <th>등급평가일</th>
              <td>{{ itemData.evaluateDate }}</td>
            </tr>
            <tr>
              <th>등급유효기간</th>
              <td>{{ itemData.periodDate }}</td>
            </tr>-->
          </template>
        </TableView>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_tertiary btn_medium" @click="onClickClose">닫기</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";

export default {
  components: {
    Popup,
    TableView,
  },
  props: {
    widthPopScore: String,
    itemData: Object,
  },
  computed: {
    gradeType() {
      const { createPoint } = this.itemData;

      if (createPoint == 0) {
        return "except";
      } else if (createPoint > 0 && createPoint < 20) {
        return "bankrupt";
      } else if (createPoint >= 20 && createPoint < 50) {
        return "bad";
      } else if (createPoint >= 50 && createPoint < 80) {
        return "normal";
      } else if (createPoint >= 80 && createPoint < 90) {
        return "good";
      } else if (createPoint >= 90) {
        return "excellence";
      }
      return "except";
    },
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
  },
};
</script>
