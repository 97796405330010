<template>
  <FilterBarBase btnSize="medium" @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl>
      <dt>요청일</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox
            class="w152"
            :dataList="radioData.dataList"
            :value.sync="radioData.selectedId"
          />
          <Input
            class="w452"
            :value.sync="inputCurrentText"
            :isFocusOnMount="true"
            placeholder="검색어를 입력하세요"
            @onEnterKey="onClickSearch"
          />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Input from "@/components/common/input/Input";

export default {
  name: "PurchaseRequestsPopupFilterBar",
  components: {
    FilterBarBase,
    DateRangeQuickSelect,
    SelectBox,
    Input,
  },
  data() {
    return {
      dates: [],
      inputCurrentText: "",
      radioData: {
        name: "OrderAnnoucePopupFilterBarRadio0",
        dataList: [
          { id: "projectName", code: "projectName", desc: "프로젝트명" },
          { id: "requester", code: "requester", desc: "구매요청자" },
          { id: "title", code: "title", desc: "구매요청명" },
        ],
        selectedId: "projectName",
      },
    };
  },
  methods: {
    onClickSearch(e) {
      // 그냥 빈 값이면 전체 조회 가능하게.
      // if( !this.inputCurrentText.trim() ) {
      //   return;
      // }

      const ret = {
        dates: this.dates,
        radioSelectedId: this.radioData.selectedId,
        inputCurrentText: this.inputCurrentText,
      };

      this.$emit("onClickSearch", ret);
    },
    onClickReset() {
      this.dates = [];
      this.inputCurrentText = "";
      this.selectedId = "title";
    },
  },
};
</script>
