<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>
      <div class="group_form">
        <DialougeItem v-if="item.itemName" :itemCode="item.itemCode">
          <template v-slot:dialogueHead>
            <Input
              :value.sync="item.itemName"
              :isDisabled="true"
              :maxLength="200"
              @focusout="onFocusout"
            />
          </template>
        </DialougeItem>
        <Input
          v-else
          :value.sync="item.itemName"
          :isDisabled="true"
          :maxLength="200"
          @focusout="onFocusout"
        />
        <button class="btn_search" @click="$emit('onClickSearchGoodsName')">
          <span class="ico_purchase ico_search" />
        </button>
      </div>
    </td>
    <td>
      <InputNumber :value.sync="item.quantity" />
    </td>
    <td>
      <button
        class="btn_small btn_fourthly"
        :disabled="isDisabledRemove"
        @click="() => $emit('onClickRemove')"
      >
        삭제
      </button>
    </td>
  </tr>
</template>

<script>
import DialougeItem from "@/components/common/dialouge/DialougeItem";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Input from "@/components/common/input/Input";
import InputNumber from "@/components/common/input/InputNumber";
import { getMoneyToNumber } from "@/utils/stringUtils";

export default {
  name: "EstimateGoodsListLine",
  components: {
    DialougeItem,
    InputAutoMoney,
    Input,
    InputNumber,
  },
  props: {
    index: Number,
    item: Object,
    isDisabledRemove: Boolean,
    purchasePriceItem: Object,
  },
  data() {
    return {
      currentList: [
        { code: "EA", name: "EA" },
        { code: "식", name: "식" },
        { code: "BOX", name: "Box" },
        { code: "직접입력", name: "직접입력" },
      ],
      currentSelected: { code: "EA", name: "EA" },
    };
  },
  computed: {
    accountInput() {
      return this.item.accountName && this.item.accountName
        ? `${this.item.accountName} (${this.item.accountCode})`
        : "";
    },
    price() {
      const { unitPrice, quantity } = this.item;

      if (!unitPrice || !quantity) return "0";

      const nUnitPrice = getMoneyToNumber(unitPrice);
      const nAmount = getMoneyToNumber(quantity);

      const nResult = nUnitPrice * nAmount;

      const strResult = String(nResult);

      return strResult;
    },
  },
  watch: {
    price(value) {
      this.item.price = value;
      this.$emit("updatePrice", this.index);
    },
  },

  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
<style lang="scss" scoped>
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
</style>
